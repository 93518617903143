.headerCell {
    font-weight: bold;
}

.paperRoot {
    max-width: 100%;
}

.typography {
    margin-right: 8px;
    color: #9a1c12;
}

.tableRow:nth-of-type(even) {
    background: #f3f3f3;
    color: black;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-evenly;
    padding: 8px;
}

.table {
    padding: 8px;
}

.tableColumn {
    display: flex;
    flex-basis: content;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}