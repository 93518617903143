.table {
    margin-top: 8px;
    margin-bottom: 8px;
}

.column {
    overflow-x: auto;
}

.extendedIcon {
    margin-right: 8px;
    color: #FFF;
}

.buttonText {
    color: #FFF;
}

.row {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    align-items: center;
}

.growRow {
    flex-direction: row;
    flex-grow: 1;
}

.growColumn {
    flex-direction: column;
    flex-grow: 1;
}

.tableRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 8px;
}

.margin {
    margin-right: 8px;
    color: #FFFFFF;
    max-width: 200px;
}

.marginLeft {
    padding-left: 8px;
    color: #FFFFFF;
}

.paper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    background-color: rgba(0,0,0,.05);
}

.tr:nth-of-type(even) {
    background: #f3f3f3;
    color: black;
}

.td {
    border: 1px solid rgba(0,0,0,0.12);
    align-content: center;
    justify-content: center;
}

.toolbar {
    display: flex;
    background-color: #efeeed;
    color: #9a1c12;
}

.typography {

}

.celldata {
    font-size: 16px;
}

.celldatabold {
    font-size: 16px;
    font-weight: bold;
}

.moreButton {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: flex-start;
    padding: 8px;
}

.popover {
    padding: 8px;
}

/* Changing placeholder text color/opacity */
.MuiInputBase-input.MuiInput-input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    rgb(153, 0, 0);
    opacity:  1;
  }
.MuiInputBase-input.MuiInput-input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    rgb(153, 0, 0);
    opacity:  1;
}
.MuiInputBase-input.MuiInput-input::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    rgb(153, 0, 0);
    opacity:  1;
}
.MuiInputBase-input.MuiInput-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:    rgb(153, 0, 0);
    opacity:  1;
}
.ordered-list{
    padding-left:20px;
}